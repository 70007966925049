<template>
  <div class="login-flow-tpl" :class="{ compact }" :data-test-id="elementTest">
    <div class="inner-container" :class="{ 'u-p-less': compact }" :data-test-id="elementTest + '-container'">
      <div class="login-content" :data-test-id="elementTest + '-content'">
        <div class="login-content-wrapper" :data-test-id="elementTest + '-wrapper'">
          <div class="login-popup no-bg no-padding" :data-test-id="elementTest + '-popup'">
            <div class="login-title-wrapper" :data-test-id="elementTest + '-title-wrapper'">
              <button :data-test-id="elementTest + '-back-button'">
                <div
                  class="icon-arrow-left u-bg-steel-grey"
                  :data-test-id="elementTest + '-back-button-icon'"
                  @click="compact ? $emit('go-back') : $router.back()"
                />
              </button>
              <h1 :data-test-id="elementTest + '-title'">{{ $t('activateAccount.title') }}</h1>
            </div>
            <p class="login-subtitle" :data-test-id="elementTest + '-subtitle'">
              {{ $t('activateAccount.subtitle', emailOrPhone) }}
            </p>
            <form :data-test-id="elementTest + '-form'" @submit.prevent="activate">
              <InputField
                v-model="code"
                :input-id="'code'"
                input-type="text"
                :field-title="$t('fields.activationCode')"
                :placeholder-value="$t('fields.activationCode')"
                :element-test="elementTest + '-code'"
              >
                <template #icon>
                  <div class="icon-sms u-bg-steel-grey" :data-test-id="elementTest + '-code-icon'" />
                </template>
              </InputField>
              <div v-if="isLoading" class="loader" :data-test-id="elementTest + '-loader'" />
              <p v-if="error" class="error" :data-test-id="elementTest + '-error'">
                {{ error }}
              </p>
              <MainButton
                :label="forgotPassword ? $t('actions.resetPassword') : $t('actions.activateAccount')"
                :data-test-id="forgotPassword ? `${elementTest}-reset-password` : `${elementTest}-activate-account`"
                tag="button"
                class-variant="full-width"
                :disabled="code === null || code === ''"
              />
            </form>
            <MainButton
              tag="button"
              :label="$t('actions.resendCode', retryTime)"
              class-variant="full-width"
              :class="retryTime > 0 ? 'btn-light-grey-white' : 'u-bg-bg-grey u-c-dark-blue btn-hover-light-grey'"
              :disabled="retryTime > 0"
              :data-test-id="elementTest + '-resend-code'"
              @click="sendSMS"
            />
          </div>
        </div>
        <ImageLazy
          src="https://d3brsr9pdomwt0.cloudfront.net/illustrations/login-persons.webp"
          :alt="$t('brandName')"
          :title="$t('brandName')"
          :data-test-id="elementTest + '-illu'"
          class="login-main-illu"
        />
      </div>
    </div>
  </div>
</template>

<script>
import API from '@/mixins/sp-api-mixin'
import i18nMx from '@/mixins/i18n-mixin'
import MainButton from '@/components/Buttons/MainButton/MainButton'
import InputField from '@/components/InputField/InputField'

export default {
  name: 'ActivationForm',
  components: {
    MainButton,
    InputField,
  },
  mixins: [API, i18nMx],
  props: {
    compact: { type: Boolean, default: false },
    elementTest: { type: String, default: '' },
  },
  emits: ['go-back', 'confirm-activation'],
  data() {
    return {
      code: null,
      retryTime: 60,
      timer: null,
    }
  },
  computed: {
    emailOrPhone() {
      return this.$store.getters['activation/emailOrPhone']
    },
    forgotPassword() {
      return this.$store.getters['activation/forgotPassword']
    },
  },
  mounted() {
    this.startRetryTimer()
  },
  beforeUnmount() {
    clearInterval(this.timer)
  },
  methods: {
    startRetryTimer() {
      this.retryTime = 60
      this.timer = setInterval(() => {
        this.retryTime -= 1
        if (this.retryTime <= 0) {
          clearInterval(this.timer)
        }
      }, 1000)
    },
    sendSMS() {
      const { $api } = useNuxtApp()
      this.spRequest({
        req: $api().user.forgotPassword(this.emailOrPhone),
      }).finally(() => {
        this.startRetryTimer()
      })
    },
    activate() {
      const { $api, $currentUser } = useNuxtApp()
      // Activate/reset password with code
      if (!this.isLoading && this.emailOrPhone && this.code) {
        this.isLoading = true
        this.error = null
        this.spRequest({
          req: $api().user.activate(this.emailOrPhone, this.code, this.forgotPassword),
        })
          .then(response => {
            if (response.user !== null && response.user !== undefined) {
              // this.$auth.options.redirect = false
              return $currentUser().login(response.token, response.user)
            }
            const { country } = useCurrentLocale()
            this.error = this.$te(`backendError.${response?.message}`)
              ? this.$t(`backendError.${response?.message}`, { phone: country?.phoneNumber })
              : this.$t('backendError.unknown', { phone: country?.phoneNumber })
            throw new Error(response?.message || 'unknown')
          })
          .then(() => {
            if (this.compact) {
              this.$emit('confirm-activation', true)
            } else {
              const localePath = useLocalePath()
              this.$router.push(localePath('login-set-password'))
            }
          })
      }
    },
  },
}
</script>

<style lang="scss">
@import '@/styles/partials/tpl/login-flow';
</style>
